import I18n from "i18n-js";
const en = require("./en.json");
const ar = require("./ar.json");
import { getStorageData, setStorageData } from "../../framework/src/Utilities";

I18n.fallbacks = true;
I18n.translations = {
  ar,
  en,
};

const setLocale = async () => {
  let defaultValue = await getStorageData("appLanguage") || "ar";
  if (!defaultValue) {
    defaultValue = "ar";
    await setStorageData("appLanguage", defaultValue);
   }

  I18n.locale = defaultValue;
};

setLocale();
export default I18n;