import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface PrivacyPolicyAttributes {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    title_ar: string;
    description_ar: string;
  };
}
interface APIPayloadType {
  contentType?: string;
  endPoint: string;
  body?: object;
  token?: string;
  method: string;
  type?: string;
}

interface ValidResponseType {
  data: {
    attributes: {
      meta_description: string;
      page_name: string;
      meta_title: string;
    }
  }
  message: string;
  meta: {
    message: string;
  }
};

interface MetaData {
  data: {
    attributes: {
      page_name: string;
      meta_title: string;
      meta_description: string;
    }
  }
}

interface PrivacyPolicy {
  id: string;
  type: string;
  attributes: PrivacyPolicyAttributes;
}

interface PrivacyPolicyState {
  attributes: any;
  privacyPolicyData: PrivacyPolicy; 
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  privacyPolicyDatadata:PrivacyPolicyState[];
  selecteLanguage:string
  metaData:MetaData
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  seoCallId: string = "";
  privacyPolicyDataApiCallId:string|Message=''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      privacyPolicyDatadata:[] ,
      selecteLanguage:"",
      metaData: {
        data: {
          attributes: {
            page_name: "",
            meta_title:"",
            meta_description:""
          }
        }
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.privacyPolicyDataApiCallId !== null &&
      this.privacyPolicyDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      if (responseJson1) {
        this.setState({
           privacyPolicyDatadata: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        
      }
      
    }
    this.recevieFunction(message)   
    // Customizable Area End
  }

  // Customizable Area Start
  recevieFunction = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(apiRequestCallId, responseJson);
      }
     
    }
  }
  async componentDidMount() {
    this.getPrivacyPolicyData();
    this.postSeo()
    const language = await getStorageData("appLanguage") || "ar";
      this.setState({ selecteLanguage: language })
  }
  
  getPrivacyPolicyData = async () => {
      this.setState({ loading: true });
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
  
      const privacyPolicyrequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.privacyPolicyDataApiCallId = privacyPolicyrequestMessage.messageId;
  
      privacyPolicyrequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.privacyPolicyApi
      );
  
      privacyPolicyrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      privacyPolicyrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethod
      );
  
      runEngine.sendMessage(privacyPolicyrequestMessage.id, privacyPolicyrequestMessage);
    return true;
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.seoCallId) {
      this.seoSucessCallBack(responseJson);
    }
  };

  seoSucessCallBack = (responseJson: ValidResponseType) => { this.setState({ metaData: responseJson, loading: false }) }

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessagePp = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagePp.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessagePp.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessagePp.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessagePp.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessagePp.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessagePp.id, requestMessagePp);
    return requestMessagePp.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson.data)
  };

  postSeo = async () => {
    this.setState({ loading: true })
    this.seoCallId = await this.apiCall({
      method: configJSON.postMethod,
      endPoint: `${configJSON.seoEndPoint}PrivacyPolicy`,
      contentType: configJSON.validationApiContentType,
    });
  };
  // Customizable Area End
}
