import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled
} from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import "@mui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import { Helmet } from 'react-helmet';

import CustomisableUserProfilesController, {
  configJSON,
  Props,
} from "./CustomisableUserProfilesController";
import { getAlignmentRightByLanguage, getDirectionLeftByLanguage, getPoppinsFontByLanguage, translate } from "../../../../packages/components/src/Translate";

import {crossIcon,logo, crossIconBlue, userAccount} from "./assets";
import Loader from "../../../../packages/components/src/Loader.web";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    // Customizable Area Start
    const {metaData} =this.state
    return (
      <>
        <StyledEngineProvider injectFirst>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{metaData.data.attributes.meta_title}</title>
            <meta name="description" content={metaData.data.attributes.meta_description} />
          </Helmet>
          <div id="main_block" >
            <NavigationMenu navigation={this.props.navigation} id="" isFooter={true} >
              <ThemeProvider theme={theme}>
                <MainWrapper>
                  {this.state.logoutPopup &&
                    <Box className="popupWrapper">
                      <Box className="popupBox">
                        <Box className="subContainer">
                          <Box className="subContainerTwo">
                            <img className="crossIcon" src={crossIcon.default} onClick={this.handleCancelPopUp} />
                          </Box>
                          <Box className="subContainerThree">
                            <Typography className="popupTypo">{translate('logoutProfile')}</Typography>
                            <Typography className="popupTypography">
                              {translate('logoutConfirmationProfile')}
                            </Typography>
                            <button className="logoutPopupButton" onClick={this.handleUserLogout}>
                              {translate('logoutButtonProfile')}
                            </button>
                            <button className="cancelLogoutButton" onClick={this.handleCancelPopUp}>
                              {translate('cancelButtonProfile')}
                            </button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  }
                  {this.state.menuPopup &&
                    <Box className="mainBox">
                      <Box className="firstBox">
                        <Box className="secondBox">
                          <img src={logo.default}
                            className="logoImage"
                            onClick={() => this.onPressMenuItem("Home")}
                            style={{ cursor: 'pointer' }}
                            data-test-id="homeLogoIcon"
                          />
                          <img src={crossIconBlue.default} className="crossImage" onClick={this.handleCancelPopupMobile} />
                        </Box>
                        <Box className="thirdBox">
                          <Button
                            sx={{ ...Styles.greyButton, textTransform: 'none' }}
                            onClick={() => this.onPressMenuItem("CustomisableUserProfiles")}
                            data-test-id="btnLogin"
                          >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px', color: "#1D36BB" }}>
                              <img style={{ width: "24px", height: "24px" }} src={userAccount.default} alt="Header Logo" />
                              {translate('myProfileProfile')} {/* Translated text for "My Profile" */}
                            </Box>
                          </Button>
                          <button
                            className="buttonLogout"
                            onClick={this.handlelogoutMobile}
                          >
                            {translate('logoutProfile')}
                          </button>
                        </Box>
                        <button className="buttonLogoutSecond" onClick={this.handlelogoutMobile}>
                          {translate('logoutProfile')}
                        </button>
                      </Box>
                    </Box>
                  }
                  <Box 
                   sx={{
                    display: "flex",
                    height: "100vh",
                    marginTop: {
                      xs: "80px", 
                      sm: "80px", 
                      md: "130px", 
                      lg: "130px", 
                      xl: "130px", 
                    },
                  }}
                  >
                    {/* Sidebar */}
                    <Box className="containerFirst">
                      <Box style={{ display: "flex", marginTop: '20px' }}>
                        <Box className="borderLeft"></Box>
                        <button className="buttonMyRentals">{translate('myRentalsProfile')}</button>
                      </Box>
                      <button onClick={this.handleLogoutPopup} className="buttonLogoutWeb">{translate('logoutProfile')}</button>
                    </Box>
                    {/* Main Content */}
                    <Box
                      sx={{
                        flex: 1, 
                        backgroundColor: "#F5F6FA",
                        color: "white",
                        display: "flex",
                      }}
                    >
                      <Box className="containerSecond">
                        <Typography className="headingTypo">{translate('myRentalsProfile')}</Typography>

                        <Box className="insideBox">
                          {
                            this.state.userRentalData && this.state.userRentalData.length > 0 ? (
                              this.state.userRentalData.map((userRentalData) => (
                                <Box className="rentalsListBox" key={userRentalData.rent_value}>
                                  <Box className="rentalData">
                                    <Typography className="rentalSAR">
                                      {translate('yourAnnualRental')} {userRentalData.rent_value}
                                    </Typography>
                                    <Typography className="rentalDate">{translate('date')} {userRentalData.created_date}</Typography>
                                  </Box>
                                  <Box className={this.statusClassName(userRentalData.status)}>
                                    <Typography className={this.statusClassName(userRentalData.status)} style={{ fontSize: "14px", fontWeight: "700", fontFamily: getPoppinsFontByLanguage() }}>
                                      {this.userStatus(userRentalData.status)}
                                    </Typography>
                                  </Box>
                                </Box>
                              ))
                            ) : (
                              <Box className="noRentalsMessage">
                                <Typography>{this.state.noRecordMeesage}</Typography>
                              </Box>
                            )
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </MainWrapper>
              </ThemeProvider>
            </NavigationMenu>
          </div>

          <Loader loading={this.state.isLoader} />
        </StyledEngineProvider>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

const Styles = {
  greyButton: {
    fontFamily:getPoppinsFontByLanguage() ,
    backgroundColor: "#F2F2F7",
    color: "#1D36BB",
    padding: "5.2px 17.1px",
    fontWeight:500,
    fontSize:"17px",
    borderRadius: "8px",
    '&:hover': {
      backgroundColor: "#e0e0e0",
    },
}
};
const MainWrapper = styled(Box)(({theme}) => ({
  "& .mainWrapper":{
    display:"flex",
    width:"100vw",
    height:"100vh",
    flexDirection:"column" as "column",
    paddingTop:"5rem"
  },
  "& .header":{
    display:"flex",
    flexDirection:"row",
    padding: "10px 0 6px",
    width:"100vw",
    background: "rgba(255, 255, 255, 1)",
    justifyContent:"space-between",
    alignItems:"center",
  },
  "& .logoBox":{
    marginLeft:"7%",
    [theme.breakpoints.down(901)]: {
      marginLeft:"2%",
      marginRight:"2%",
    }
  },
  "& .logoImg":{
    width:"100%",
    cursor: 'pointer'
  },
  "& .buttonBox":{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    gap:"16px",
    marginRight:"7%",
    [theme.breakpoints.down(901)]: {
      display:"none",
    }
  },
  " & .buttonSubmitApp": {
    background: "rgba(29, 54, 187, 1)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "20px",
    fontWeight: 700,
    borderRadius: "6px",
    border:"none",
    padding:"18px 21px",
    cursor:"pointer",
    [theme.breakpoints.down(900)]: {
      fontSize: "20px"
    },
},
"& .buttonNameWeb":{
  background: "rgba(242, 242, 247, 1)",
  fontSize: "20px",
  fontWeight: 700,
  borderRadius: "6px",
  border:"none",
  padding:"18px 21px",
  color: "rgba(29, 54, 187, 1)",
  cursor:"pointer",
  [theme.breakpoints.down(900)]: {
    fontSize: "20px"
  },
},
"& .menuBox":{
  padding:"3%",
  [theme.breakpoints.up(900)]: {
    display:"none",
  }
},
"& .menuIcon":{
  width:"100%",
  cursor:"pointer",
},
  "& .mainContainer":{
    display:"flex",
    height:"calc(100vh)",
  },
  "& .containerFirst":{
    width:"20vw",
    minHeight:"100%",
    background: "rgba(255, 255, 255, 1)",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    borderRight: "1.5px solid #E0E0E0",
    [theme.breakpoints.down(901)]: {
      display:"none",
    }
  },
  "& .borderLeft": {
    width: "5px",
    background: "rgb(29, 54, 187)",
    borderRadius: "0 20px 20px 0",
    height: "53px",
    marginRight: "10px"
  },
  " & .buttonMyRentals": {
    background: "rgba(255, 255, 255, 1)",
    color: "rgba(32, 34, 36, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "14px",
    fontWeight: 600,
    width: "100%",
    height: "53px",
    padding:"9px 5px",
    border:"none",
    textAlign:"left",
    cursor:"pointer",
    [theme.breakpoints.down(900)]: {
      fontSize: "20px"
    }
  },
  " & .buttonLogoutWeb": {
  background: "rgba(255, 255, 255, 1)",
  border: "1px solid rgba(226, 232, 240, 1)",
  color: "rgba(32, 34, 36, 1)",
  fontFamily:getPoppinsFontByLanguage() ,
  fontSize: "14px",
  fontWeight: 600,
  width: "100%",
  height: "70px",
  padding:"9px 5px",
  cursor:"pointer",
},
  "& .containerSecond":{
    width:"80vw",
    background: "#F5F6FA",
    display:"flex",
    flexDirection:"column",
    padding:"3vw",
    [theme.breakpoints.down(901)]: {
      width:"100%",
      padding:"2vw",
    }
  },
  "& .insideBox": {
    height: '610px',
    overflowY: "auto"
  },
  "& .headingTypo":{
    color: "rgba(32, 34, 36, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "32px",
    fontWeight: 700,
    [theme.breakpoints.down(901)]: {
     fontSize: "20px",
    },
  },
  "& .rentalsListBox":{
    display:"flex",
    flexDirection: getDirectionLeftByLanguage(),
    background: "rgba(255, 255, 255, 1)",
    padding: "30px",
    width: "calc(100% - 60px)",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "10px",
    borderRadius: "10px",
    marginTop: "20px",
    [theme.breakpoints.down(376)]: {
      flexDirection: "column",
      padding: "15px",
      width: "calc(100% - 30px)",
      alignItems: getAlignmentRightByLanguage()
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      alignItems: getAlignmentRightByLanguage()
    }
  },
  "& .rentalData":{
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    width: "100%",
    gap:"1vw",
  }, 
  "& .rentalSAR":{
    color: "rgba(0, 0, 0, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "17px",
    fontWeight: 700,
  } ,
  "& .rentalStatusDefault":{
    background: "transparent",
    width:"123px",
    height:"27px",
    borderRadius:"15px",
    color:"rgba(255, 255, 255, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .rentalStatusApproved":{
    background: "#3F9644",
    width:"123px",
    height:"27px",
    borderRadius:"15px",
    color:"rgba(255, 255, 255, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .rentalStatusUnderReview":{
    width:"123px",
    height:"27px",
    borderRadius:"15px",
    color:"rgba(255, 255, 255, 1)",
    background: "#FCBE2D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .rentalStatusUnderReject":{
    width:"123px",
    height:"27px",
    background: "rgba(190, 24, 27, 1)",
    borderRadius:"15px",
    color:"rgba(255, 255, 255, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .rentalStatus":{
    width:"123px",
    height:"27px",
    borderRadius:"15px",
    color:"rgba(255, 255, 255, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .rentalDate":{
    color: "rgba(0, 0, 0, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .popupWrapper": {
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    position: "fixed" as "fixed",
    backgroundColor: "rgba(43, 43, 43, 0.6)",
    alignItems: "center" as "center",
    display: "flex",
    justifyContent: "center" as "center",
    zIndex:99999
  },
  "& .popupBox": {
    backgroundColor: "rgba(255, 255, 255, 1)",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    flexDirection: "column" as "column",
    position: "absolute" as "absolute",
    borderRadius: "16px",
    alignItems: "center" as "center",
    padding: "1vw",
    paddingBottom: "2vw",
    margin: "1vw",
    [theme.breakpoints.down(901)]: {
      width: "60%",
      padding:"3vw"
     },
    [theme.breakpoints.down(601)]: {
      width: "80%",
      padding:"3.2vw"
     },
    [theme.breakpoints.down(450)]: {
      width: "80%",
      padding:"4vw"
     },
  },
  "& .crossIconBox": {
    width: "100%",
  },
  "& .crossIcon": {
    alignSelf: "flex-end",
    padding: "0.2vw",
    [theme.breakpoints.down(901)]: {
      width: "4vw",
      height:"4vw"
     },
    [theme.breakpoints.down(601)]: {
      width: "5vw",
      height:"5vw"
     },
    [theme.breakpoints.down(450)]: {
      width: "6vw",
      height:"6vw"
     },
  },
  "& .subContainer": {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
  },
  "& .subContainerTwo": {
    width: "100%",
    display: "flex",
    alignItems: "flex-end" as "flex-end",
    justifyContent: "end" as "end",
  },
  "& .subContainerThree": {
    width: "85%",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    gap: "2vw",
    padding: "1vw 3vw 0vw 3vw",
    [theme.breakpoints.down(601)]: {
      gap: "4vw",
     },
    [theme.breakpoints.down(450)]: {
      gap: "4vw",
     },
  },
  "& .popupTypo": {
    color: "rgba(0, 0, 0, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "28px",
    fontWeight: "700",
    textAlign: "center" as "center",
  },
  "& .popupTypography": {
    color: "rgba(0, 0, 0, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "20px",
    fontWeight: "400",
    textAlign: "center" as "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.down(601)]: {
      whiteSpace:"wrap"
     },
  },
  "& .logoutPopupButton": {
    backgroundColor: "rgba(29, 54, 187, 1)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "20px",
    fontWeight: "700",
    textAlign: "center" as "center",
    borderRadius: "6px",
    width: "100%",
    padding: "0.5vw",
    border: "none",
    cursor: "pointer",
  },
  "& .cancelLogoutButton": {
    color: "rgba(29, 54, 187, 1)",
    backgroundColor: "rgba(242, 242, 247, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "20px",
    fontWeight: "700",
    borderRadius: "6px",
    textAlign: "center" as "center",
    width: "100%",
    border: "none",
    padding: "0.5vw",
    cursor: "pointer",
  },
  "& .mainBox": {
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    position: "fixed" as "fixed",
    backgroundColor: "rgba(43, 43, 43, 0.6)",
    alignItems: "flex-start" as "flex-start",
    display: "flex",
    justifyContent: "center" as "center",
  },
  "& .firstBox": {
    backgroundColor: "rgba(255, 255, 255, 1)",
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "column" as "column",
    position: "absolute" as "absolute",
    alignItems: "center" as "center",
    padding: "0vw 1vw 1vw 1vw",
    paddingBottom: "0vw",
  },
  "& .secondBox": {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    alignItems: "center" as "center",
    width: "100%",
    padding: "15px 0",
    paddingLeft: "0px",
    border: "1px solid rgba(237, 237, 237, 1)",
  },
  "& .logoImage": {
    width: "16vw",
    height: "8vw",
    paddingLeft: "2vw",
    [theme.breakpoints.down(501)]: {
      width: "22vw",
      height: "11vw",
      paddingLeft: "4vw",     
    },
  },
  "& .crossImage": {
    width: "4vw",
    height: "4vw",
    paddingRight: "3vw",
  },
  "& .thirdBox": {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    gap: "3vw",
    padding: "2vw",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
  },
  "& .buttonName": {
    color: "rgba(29, 54, 187, 1)",
    backgroundColor: "rgba(242, 242, 247, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "20px",
    fontWeight: "700",
    borderRadius: "6px",
    textAlign: "center" as "center",
    width: "95%",
    border: "none",
    padding: "1.5vw",
    cursor: "pointer",
    [theme.breakpoints.down(501)]: {
      fontSize: "15px",
    },
  },
  "& .buttonLogout": {
    backgroundColor: "rgba(29, 54, 187, 1)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "20px",
    fontWeight: "700",
    textAlign: "center" as "center",
    borderRadius: "6px",
    width: "95%",
    padding: "1.5vw",
    border: "none",
    cursor: "pointer",
    [theme.breakpoints.down(501)]: {
      fontSize: "15px",
    }
  },
  "& .buttonLogoutSecond": {
    background: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(226, 232, 240, 1)",
    color: "rgba(32, 34, 36, 1)",
    fontFamily:getPoppinsFontByLanguage() ,
    fontSize: "14px",
    fontWeight: 600,
    width: "100%",
    height: "70px",
    padding: "9px 20px",
    cursor: "pointer",
    textAlign: "start" as "start",
  },
}))
// Customizable Area End
