import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const config = require("../../../framework/src/config");
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData ,removeStorageData } from "../../../framework/src/Utilities";

export interface SuccesResponse {
  customer: {
    customer_id: string;
    lean_customer_id:string;
  };
  message: string;
  customer_access_token: {
    access_token: string;
  }

}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}


interface InvalidResponseType {
  error: Array<string>;
  errors: Array<string>;
  customer: {
    customer_id: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  Lean: any;
  componentPage: string;
  customer_id: string;
  sendDataMsg: string,
  isSnackbarError: boolean;
  isSnackbarSuccess: boolean;
  customerAccessToken: string;
  isLoader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfopenbankingapi1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createCustomerApiCallID: string = "";
  leanCustomerTokenCallId: string = "";
  storeEntityCallId: string = "";
  checkCustomerCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      Lean: '',
      componentPage: "home",
      customer_id: '',
      sendDataMsg: '',
      isSnackbarError: false,
      isSnackbarSuccess: false,
      customerAccessToken: "",
      isLoader: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.createCustomerApiCallID === apiRequestCallId) {
      if (responseJson.error) {
        this.setState({
          sendDataMsg: responseJson.error,
          isSnackbarError: true,
          isLoader: false
        })
      }
      else {
        this.setState(
          {
            customer_id: responseJson.customer.customer_id,
            isLoader: false
          },
          () => {
            this.postLeanCustomerToken()
          }
        );
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }

      if (responseJson && responseJson.errors || responseJson.error) {
        this.apiFailureCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.checkURL(config.baseURL)
    const url = new URL(window.location.href);
    const entityIdParam = url.searchParams.get('entity_id');

    if (entityIdParam) {
      setStorageData("entityIdParam", entityIdParam);
    }

    else {
      await removeStorageData('entityIdParam');
    }
    const entity = await getStorageData("entityIdParam");
    if (entity) {
      this.postStoreEntity();
    }
  };

  checkURL = (serverUrl: string) => {
    if (serverUrl === "https://opensooqfs-632983-ruby.b632983.dev.eastus.az.svc.builder.cafe") {
      setStorageData("leanURL", "https://opensooqfs-632983-react.b632983.dev.eastus.az.svc.builder.cafe");
    } 
    
    else if (serverUrl === "https://opensooqfs-632983-ruby.b632983.stage.eastus.az.svc.builder.ai") {
      setStorageData("leanURL", "https://opensooqfs-632983-react.b632983.stage.eastus.az.svc.builder.ai");
    }

     else if (serverUrl === "https://opensooqfs-632983-ruby.ksa-1.dev.jeddah.az.svc.builder.cafe") {
      setStorageData("leanURL", "https://opensooqfs-632983-react.ksa-1.dev.jeddah.az.svc.builder.cafe");
    }
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: SuccesResponse) => {
    if (apiRequestCallId === this.leanCustomerTokenCallId) {
      this.leanCustomerSuccess(responseJson)
    }
    if (this.createCustomerApiCallID === apiRequestCallId) {
      this.setState({ customer_id: responseJson.customer.customer_id, },
        () => {
          this.postLeanCustomerToken()
        });
    }

    if (apiRequestCallId === this.storeEntityCallId) {
      this.storeEntitySuccess(responseJson)
    };

    if (apiRequestCallId === this.checkCustomerCallId) {
      this.checkCustomerCallSuccess(responseJson)
    };
  };

  apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.leanCustomerTokenCallId) {
      this.setState({
        isLoader: false
      })
      alert(JSON.stringify(responseJson.errors[0]))
    }
    if (apiRequestCallId === this.storeEntityCallId) {
      this.storeEntityFailure(responseJson)
    }

    if (apiRequestCallId === this.checkCustomerCallId) {
      this.checkCustomerCallFailure(responseJson)
    }
  };


  checkCustomerCallSuccess = (responseJson: SuccesResponse) => {
    this.setState({
      isLoader: false
    });

    this.setState({
      customer_id: responseJson.customer?.lean_customer_id,
    },
      () => {
        this.postLeanCustomerToken()
      })
  };

  checkCustomerCallFailure = (responseJson: InvalidResponseType) => {
    this.setState({
      isLoader: false
    },
      () => {
        this.handleSubmit()
      });
  };

  storeEntitySuccess = (responseJson: SuccesResponse) => {
    this.setState({
      isLoader: false
    })
    this.onPressMenuItem("KnowYourCustomerKycVerificationAccount")
  };


  onPressMenuItem = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  };

  storeEntityFailure = (responseJson: InvalidResponseType) => {
    this.setState({
      isLoader: false
    },()=>{
      alert(JSON.stringify(responseJson.errors[0]))
    })
  };

  leanCustomerSuccess = (responseJson: SuccesResponse) => {
    this.setState({ customerAccessToken: responseJson.customer_access_token.access_token, isLoader: false }, () => {
      this.leanConnection()
    })
  }

  isValidResponse = (responseJson: SuccesResponse) => {
    return (
      (responseJson.message)
    );
  };

  handleSnackbarself = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.snackbarAlertMsg) { return; }
    this.setState({ isSnackbarError: false })
  };

  handleSubmit = async () => {
    this.setState({
      isLoader: true

    })
    const loginToken = await getStorageData("login_token", true)

    const header = {
      token: loginToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.createCustomerApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCustomerApiEndPoint}?${loginToken}`)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      ""
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  leanConnection = async () => {
    try {
      const leanURL = await getStorageData("leanURL");
      const appLanguage = await getStorageData("appLanguage");

    (window as any)?.Lean.connect({
            app_token: configJSON.leanClientId,
            permissions: [
                "identity",
                "accounts",
                "balance",
                "transactions",
                "identities",
                "scheduled_payments",
                "standing_orders",
                "direct_debits",
                "beneficiaries",
            ],
            customer_id:this.state.customer_id,
            sandbox: true,
            access_token: this.state.customerAccessToken,
            fail_redirect_url: `${leanURL}/openbanking`,
            success_redirect_url: `${leanURL}/openbanking`,
            account_type: "personal",
            language: appLanguage
        });
    } 
    catch (error) {
        alert(`Lean Connection Error`);
    }
};

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const token = await getStorageData("login_token", true)
    const requestMessageCatalogueColorCollection = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
      "token": token
    };
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageCatalogueColorCollection.id, requestMessageCatalogueColorCollection);
    return requestMessageCatalogueColorCollection.messageId;
  };

  postLeanCustomerToken = async () => {
    this.setState({
      isLoader: true,
    })
    this.leanCustomerTokenCallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.leanCustomerTokenEndPoint + "?customer_id=" + this.state.customer_id
    });
  };

  postStoreEntity = async () => {
    const entity = await getStorageData("entityIdParam");

    this.setState({
      isLoader: true,
    })
    const body = {
      entity_id: entity
    }
    this.storeEntityCallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.storeEntityEndPoint,
      body: body
    });
  };

  checkCustomer = async () => {
    this.setState({
      isLoader: true,
    })
    this.checkCustomerCallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.checkCustomerApiEndPoint,
    });
  };

  // Customizable Area End
}
