// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType {
  data: {
    attributes: {
      page_name: string;
      meta_title: string;
      meta_description: string;
    }
  }
  message: string;
  meta: {
    message: string;
  }
};

interface MetaData {
  data: {
    attributes: {
      page_name: string;
      meta_title: string;
      meta_description: string;
    }
  }
}

interface TermsConditionAttributes {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    title_ar: string;
    description_ar: string;
  };
}



interface TermsCondition {
  id: string;
        type: string;
  attributes: TermsConditionAttributes;
      }

interface TermsConditionState {
  attributes: any;
  termsConditionData: TermsCondition; 
}

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  termsCondtiondata:TermsConditionState[];
  selectedLanguage:string
  metaData:MetaData;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  termscondtiondataapicallId:string|Message=''
  seoCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      termsCondtiondata:[] ,
      selectedLanguage:"",
      metaData: {
        data: {
          attributes: {
            page_name: "",
            meta_title:"",
            meta_description:""
          }
        }
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recevieFunction(message)   

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.termscondtiondataapicallId !== null &&
      this.termscondtiondataapicallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse1 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson1) {
        this.setState({
           termsCondtiondata: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        
      }
      
    }
   

    
    // Customizable Area End
  }

  // Customizable Area Start
  recevieFunction = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(apiRequestCallId, responseJson);
      }
     
    }
  }
async componentDidMount() {
  this.getTermsandConditionData();
  this.postSeo()
  const language = await getStorageData("appLanguage") || "ar";
    this.setState({ selectedLanguage: language })
}

getTermsandConditionData = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.termscondtiondataapicallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.seoCallId) {
      this.seoSucessCallBack(responseJson);
    }
  };

  seoSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      loading: false,
      metaData: responseJson
    })
  }

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageTc = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageTc.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageTc.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageTc.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessageTc.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageTc.addData(
        
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessageTc.id, requestMessageTc);
    return requestMessageTc.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson.data)
  };

  postSeo = async () => {
    this.setState({ loading: true })
    this.seoCallId = await this.apiCall({
      method: configJSON.postMethod,
      endPoint: `${configJSON.seoEndPoint}TermsAndConditions`,
      contentType: configJSON.validationApiContentType,
    });
  };
  // Customizable Area End
}
