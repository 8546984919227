import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import React from "react";

interface InvalidResponseType {
  error: string;
};
interface ValidResponseType {
  data: {
    attributes: {
      page_name: string;
      meta_title: string;
      meta_description: string;
    }
  }
  email: string;
  phone_number: string;
  title: string;
  body: string;
  title_ar: string;
  body_ar: string;
  facebook_url: string;
  facebook_visible: boolean;
  instagram_url: string;
  instagram_visible: boolean;
  twitter_url: string;
  twitter_visible: boolean;
  snapchat_url: string;
  snapchat_visible: boolean;
  tiktok_url: string;
  tiktok_visible: boolean;
  linkedin_url: string;
  linkedin_visible: boolean;  
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}
import { FormikHelpers } from 'formik';
interface FormValues {
  name: string;
  mobileNumber: string;
  type: string;
  message: string;
}

interface ContactDetails {
  email: string;
  phone_number: string;
  title: string;
  body: string;
  title_ar: string;
  body_ar: string;
  facebook_url: string;
  facebook_visible: boolean;
  instagram_url: string;
  instagram_visible: boolean;
  twitter_url: string;
  twitter_visible: boolean;
  snapchat_url: string;
  snapchat_visible: boolean;
  tiktok_url: string;
  tiktok_visible: boolean;
  linkedin_url: string;
  linkedin_visible: boolean;
}


interface MetaData {
  data: {
    attributes: {
      page_name: string;
      meta_title: string;
      meta_description: string;
    }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  sendDataMsg: string;
  isSnackbarError: boolean;
  isSnackbarSuccess: boolean;
  isLoader:boolean;
  contactDetails:ContactDetails;
  appLanguage:string;
  isMobile: boolean;
  copied: boolean;
  metaData: MetaData,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  contactUsMobileApiCallID: any;
  getContactDetailsCallId = "";
  seoCallId: string = "";
  firstDivRef:any = React.createRef();
  formResetHandler: (() => void) | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.contactUsMobileApiCallID = "";

    this.state = {
      appLanguage:"",
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      sendDataMsg: '',
      isSnackbarError: false,
      isSnackbarSuccess: false,
      isLoader:false,
      isMobile: this.checkIsMobile(),
      copied: false,
      contactDetails:
      {
        email: "",
        phone_number: "",
        title: "",
        body: "",
        title_ar: "",
        body_ar: "",
        facebook_url: "",
        facebook_visible: false,
        instagram_url: "",
        instagram_visible: false,
        twitter_url: "",
        twitter_visible: false,
        snapchat_url: "",
        snapchat_visible: false,
        tiktok_url: "",
        tiktok_visible: false,
        linkedin_url: "",
        linkedin_visible: false,
      },
      metaData: {
        data: {
          attributes: {
            page_name: "",
            meta_title:"",
            meta_description:""
          }
        }
      },    };
    window.addEventListener("resize", this.handleResizeWindow);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.postSeo();
    this.appLanguageSelected()
    this.getContactDetailsApi()
    this.firstDivRef.current.scrollIntoView();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      this.handleApiResponse(apiRequestCallId, message);
    
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.setState({ contactUsList: responseJson.data });
        }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.props.navigation.goBack();
        }

      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.addContactApiCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
          }
        }
      }
    }
    this.recevieFunction(message)   
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount(): Promise<void> {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.handleResizeWindow);
    }
  }
  appLanguageSelected = async () => {
    const appLanguage = await getStorageData("appLanguage") || "ar";
    this.setState({ appLanguage: appLanguage })
  }
  checkIsMobile = (): boolean => {
    return typeof window !== "undefined" && window.innerWidth < 900;
  };
  handleResizeWindow = () => {
    this.setState({ isMobile: this.checkIsMobile() });
  };
  handleCopyToClipboard = (phoneNumber:string) => {
    navigator.clipboard
      .writeText(phoneNumber)
      .then(() => {
        this.setState({ copied: true }); 
        setTimeout(() => {
          this.setState({ copied: false }); 
        }, 2000);
      })
      .catch((err) => {
        console.error('Failed to copy phone number: ', err);
      });
  };
  handlePhoneNumberClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    phoneNumber: string
  ) => {
    e.preventDefault();
    this.handleCopyToClipboard(phoneNumber);
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const token = await getStorageData("login_token", true)
    const requestMessageCatalogueColorCollection = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
      "token": token
    };
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageCatalogueColorCollection.id, requestMessageCatalogueColorCollection);
    return requestMessageCatalogueColorCollection.messageId;
  };

  getContactDetailsApi = async () => {
    this.setState({
      isLoader:true
    })
    this.getContactDetailsCallId = await this.apiCall({
        contentType: configJSON.searchApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getContactDetasilsEndPoint
    });
};

  recevieFunction = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(apiRequestCallId, responseJson);
      }
     
    }
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.email) || responseJson.data
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.getContactDetailsCallId) {
      this.contactDetailsSuccessCall(responseJson)
    }
    if (apiRequestCallId === this.seoCallId) {
      this.seoSucessCallBack(responseJson);
    }
  };

contactDetailsSuccessCall =(responseJson:ValidResponseType)=>{
  this.setState({
    contactDetails:responseJson,
    isLoader:false
  })
}

openLinkInNewTab =(urlValue:string)=>{
  if (urlValue) {
    window.open(urlValue, '_blank', 'noopener,noreferrer');
  }
}

  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  handleSnackbarself = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.snackbarAlertMsg) { return; }
    this.setState({ isSnackbarError: false, isSnackbarSuccess: false })
  };

  handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    this.formResetHandler = actions.resetForm;
    const header = {
      "Content-Type": configJSON.contactUsApiContentType
    };

    let mobileNumber = values.mobileNumber || '';

    const data = {
      "user_contact": {
        "name": `${values.name}`,
        "full_phone_number": `${mobileNumber}`,
        "contact_type": `${values.type}`,
        "message": `${values.message}`
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.contactUsMobileApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ContactUsMobileApiEndPoint)


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"

    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleApiResponse(apiRequestCallId: string, message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson) return;

    if (this.contactUsMobileApiCallID === apiRequestCallId) {
      return responseJson.errors
        ? 
        this.setState({
           sendDataMsg: responseJson.errors[0], isSnackbarError: true 
          })
        : (
          this.setState({ sendDataMsg: responseJson.message, isSnackbarSuccess: true }),
          this.formResetHandler && this.formResetHandler()
        );

    }
  }

  handleFieldChange = (e: any, setFieldValue: (field: string, value: any) => void, fieldName: string) => {
    const trimmedValue = e.target.value.trimStart();
    setFieldValue(fieldName, trimmedValue);
  };
  
  handleFieldBlur = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
    handleBlur: (event: React.FocusEvent<any>) => void,
    fieldName: string
  ) => {
    const trimmedValue = e.target.value.trim();
    setFieldValue(fieldName, trimmedValue);
    handleBlur(e);
  };

  seoSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      metaData: responseJson
    })
  }

    postSeo = async () => {
      this.seoCallId = await this.apiCall({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.seoEndPoint,
        contentType: configJSON.contactUsApiContentType,
      });
    };
  
  // Customizable Area End
}
